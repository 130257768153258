<template>
  <div class="">

    <!-- Заголовок -->
    <HeroSection/>
      
    <!-- перечень фич -->
    <MainFeatures/>

    <TechFeatures/>
    
    <QrExample/>

    <Prices/>

    <Faq/>

    <GetContact/> 

  </div>
</template>

<script>
import HeroSection from './HeroSection'
import MainFeatures from './MainFeatures'
import TechFeatures from './TechFeatures'
import QrExample from './QrExample'
import Prices from './prices/Prices'
import Faq from './faq/Faq'

import GetContact from './GetContact'

export default {
  name: "land",
  components: { 
      HeroSection,
      MainFeatures,
      TechFeatures,
      QrExample,
      Prices,
      Faq,
      GetContact
   },
  computed: {
    
  },
  data() {
    return {
      startImg: require("@/assets/img/loginimg.png"),
    };
  },
  methods: {
    
  },
  
};
</script>

<style lang="scss" scoped>

</style>
