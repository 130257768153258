<template>
  <section class="price-item">

    <!-- item string -->
    <b-row class="bg-white">
      <b-col cols="6" md="8" class="p-3 border-left border-right border-bottom">
        <span>{{name}}</span>
      </b-col>
      <b-col class="p-2 text-center border-right border-bottom flex_col_center_center">
        <b-icon-check-lg v-if="freeTariffIncluded"
           variant="success" font-scale="1.4"/>
        <b-icon-x-lg v-else
            variant="danger" font-scale="1.4"/>
      </b-col>
      <b-col class="p-2 text-center border-right border-bottom flex_col_center_center">
        <b-icon-check-lg v-if="mainTariffIncluded"
           variant="success" font-scale="1.5"/>
      <b-icon-x-lg v-else
           variant="danger" font-scale="1.5"/>
      </b-col>
    </b-row>


  </section>
</template>

<script>


export default {
  name: "price-item",
  components: { 
      
  },
  props: {    
    name: String,
    freeTariffIncluded: Boolean,
    mainTariffIncluded: Boolean,
  },
  computed: {
    
  },
  data() {
    return {      
    };
  },
  methods: {
    
  },
  
};
</script>

<style lang="scss" scoped>
.price-item{
  @media (max-width: 420px) {
    font-size: 0.9rem;
  }
}
</style>
