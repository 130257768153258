<template>
  <section id="mainfeatures" class="product-features">

    <!-- features -->
    <div class="container">
      <b-row class="product-features__item">
        <b-col md="6" class="flex_col_center_center">          
          <b-img class="product-features__img" center src="/images/animation1_1.gif"/>                    
        </b-col>
        <b-col md="6" class="flex_col_center_center">
          <h3>{{ $t("blocks.main_features[0].title") }}</h3>
          <p>{{ $t("blocks.main_features[0].text") }}</p>
        </b-col>      
      </b-row>

      <b-row class="product-features__item">
        
        <b-col order-md="2" md="6" class="flex_col_center_center">
          <b-img class="product-features__img" center src="/images/animation2_2.gif"/>
        </b-col>
        <b-col order-md="1" md="6" class="flex_col_center_center">
          <h3>{{ $t("blocks.main_features[1].title") }}</h3>
          <p>{{ $t("blocks.main_features[1].text[0]") }}</p>
          <p>{{ $t("blocks.main_features[1].text[1]") }}</p>
        </b-col> 
             
      </b-row>

      <b-row class="product-features__item">
        <b-col md="6" class="flex_col_center_center">
          <b-img  class="product-features__img" center src="/images/animation3_3.gif"/>
        </b-col>
        <b-col md="6" class="flex_col_center_center">
          <h3>{{ $t("blocks.main_features[2].title") }}</h3>
          <p>{{ $t("blocks.main_features[2].text") }}</p>
        </b-col>      
      </b-row>

    </div>
  </section>
</template>

<script>


export default {
  name: "Features",
  components: { 
      
   },
  computed: {
    
  },
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  
};
</script>

<style lang="scss" scoped>

.product-features{

  &__item{
    margin-bottom: 3rem;
  }

  &__img{
    object-fit: scale-down;
    height: 16rem;
  }
}

</style>
