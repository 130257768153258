<template>
  <section id="techfeatures" class="tech-features shadow ">

    <!-- features -->
    <div class="container">     

      <b-row>
        <b-col md="3" cols="6" class="text-center tech-features__item"> 
          <div >
            <b-iconstack font-scale="5"  class="mb-4">
              <b-icon-camera stacked scale="0.8" shift-h="-4" class="text-primary" />
              <b-icon-text-center stacked  shift-h="3" shift-v="-4" class="text-secondary" />
            </b-iconstack>
            <h4 class="tech-features__description">{{ $t("blocks.tech_features[0]") }}</h4>
          </div>
        </b-col>
        <b-col md="3" cols="6" class="text-center tech-features__item"> 
          <div >
            <b-iconstack font-scale="5" class="mb-4">
              <b-icon-emoji-angry stacked scale="0.5" shift-h="-7" shift-v="-3" class="text-secondary" />
              <b-icon-phone stacked class="text-primary" />
            </b-iconstack>

            <h4 class="tech-features__description">{{ $t("blocks.tech_features[1]") }}</h4>
          </div>
        </b-col>        
        <b-col md="3" cols="6" class="text-center tech-features__item"> 
          <div>
            <b-icon-clipboard-data font-scale="5" class="mb-4 text-primary" />
            <h4 class="tech-features__description">{{ $t("blocks.tech_features[2]") }}</h4>
          </div>
        </b-col>
        <b-col md="3" cols="6" class="text-center tech-features__item"> 
          <div>
            <b-iconstack font-scale="5" class="mb-4">              
              <b-icon-phone-vibrate stacked shift-h="-3" shift-v="3" class="text-secondary" />
              <b-icon-telegram stacked scale="0.8" shift-h="3" shift-v="-3" class="text-primary" />
            </b-iconstack>
            <h4 class="tech-features__description">{{ $t("blocks.tech_features[3]") }}</h4>
          </div>
        </b-col>
      </b-row>     
      
    </div>
  </section>
</template>

<script>


export default {
  name: "TechFeatures",
  components: { 
      
   },
  computed: {
    
  },
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  
};
</script>

<style lang="scss" scoped>

.tech-features{

  background-color: gainsboro;
  padding: 5rem;  
  @media (max-width: 420px) {  
    padding: 0.5rem;
  }
  
  
  
    @media (max-width: 420px) {
      &__item{  
        padding: 1rem;
      }
    }    
  

  &__description{
    font-size: 1em;
    font-weight: 400;
  }  

}

</style>
