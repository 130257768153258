<template>
  <section id="qrexample" class="qr-example">

    <!-- examples -->
    <div class="container">     

      <b-row class="mt-4">
        <b-col md="9">
          <h2>{{ $t("blocks.examples.title")}}</h2>
          <p>{{ $t("blocks.examples.text")}}</p>
        </b-col>
      </b-row>
      
      <b-row class="mt-4">
        <b-col md="3">

          <!-- мобилка -->
          <b-form-select class="d-md-none" v-model="activeCategory">

                <b-form-select-option v-for="(category, index) in categories" 
                          :key="'category_id_'+index"
                          :value="category" >
                  {{category.name}}
                </b-form-select-option>
          </b-form-select>
          <label class="d-md-none mb-2 text-secondary mb-3">{{ $t("blocks.examples.category_description")}}</label>

          <!-- десктоп -->
          <b-button-group vertical class="d-none d-md-block ">
            <b-button v-for="(category, index) in categories" 
                :key="index + lang"
                variant="light"
                :pressed="activeCategory.id==category.id"
                @click="categorySelect(category)"
                >
              {{category.name}} 
            </b-button>
          </b-button-group>
        </b-col>
        <b-col md="9">  

          <b-row>
            <b-col cols="6" md="4" lg="3" sm="6" 
              v-for="(image, index) in imgArray" :key="index+lang">

              <b-img :src="image.url" fluid rounded 
                        :alt="image.name"
                        class="qr-example__img"/>
                <span class="qr-example__description">
                  {{image.name}}
                </span>

            </b-col>
          </b-row>
          
        </b-col>
      </b-row>     
      
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from '@/i18n';

export default {
  name: "QRExample",
  components: { 
      
   },
  computed: {
    ...mapGetters('application', ['lang']),
    imgArray() {

      let ar = []
      if (this.activeCategory) {
        ar = this.imgColection[this.activeCategory.id]
      }

      return ar;
    },
    categories(){
      let categories= [
        {name: i18n.t('blocks.examples.categories[0]'), id: 'service_business'},
        {name: i18n.t('blocks.examples.categories[1]'), id: 'catering'},
        {name: i18n.t('blocks.examples.categories[2]'), id: 'retail'},
        {name: i18n.t('blocks.examples.categories[3]'), id: 'building_control'},
        {name: i18n.t('blocks.examples.categories[4]'), id: 'other'},
      ]

      return categories
    },
    imgColection(){
      let imgColection = {
      service_business: [
        {url: '/images/qr_exampl/ru1.jpg', name: i18n.t('blocks.examples.img.service_business[0]')},
        {url: '/images/qr_exampl/ru6.jpg', name: i18n.t('blocks.examples.img.service_business[1]')},
        {url: '/images/qr_exampl/ru14.jpg', name: i18n.t('blocks.examples.img.service_business[2]')},
        {url: '/images/qr_exampl/ru4.jpg', name: i18n.t('blocks.examples.img.service_business[3]')},
        {url: '/images/qr_exampl/ru11.jpg', name: i18n.t('blocks.examples.img.service_business[4]')},
        {url: '/images/qr_exampl/ru16.jpg', name: i18n.t('blocks.examples.img.service_business[5]')},
        {url: '/images/qr_exampl/ru13.jpg', name: i18n.t('blocks.examples.img.service_business[6]')},
        {url: '/images/qr_exampl/ru7.jpg', name: i18n.t('blocks.examples.img.service_business[7]')},
        {url: '/images/qr_exampl/ru26.jpg', name: i18n.t('blocks.examples.img.service_business[8]')},
      ],
      catering: [        
        {url: '/images/qr_exampl/ru22.jpg', name: i18n.t('blocks.examples.img.catering[0]')},  
        {url: '/images/qr_exampl/ru12.jpg', name: i18n.t('blocks.examples.img.catering[1]')},      
        {url: '/images/qr_exampl/ru23.jpg', name: i18n.t('blocks.examples.img.catering[2]')},
        {url: '/images/qr_exampl/ru6.jpg', name: i18n.t('blocks.examples.img.catering[3]')},
        {url: '/images/qr_exampl/ru7.jpg', name: i18n.t('blocks.examples.img.catering[4]')},
        {url: '/images/qr_exampl/ru1.jpg', name: i18n.t('blocks.examples.img.catering[5]')},        
        {url: '/images/qr_exampl/ru4.jpg', name: i18n.t('blocks.examples.img.catering[6]')},
      ],
      retail: [
        {url: '/images/qr_exampl/ru2.jpg', name: i18n.t('blocks.examples.img.retail[0]')},
        {url: '/images/qr_exampl/ru5.jpg', name: i18n.t('blocks.examples.img.retail[1]')},
        {url: '/images/qr_exampl/ru3.jpg', name: i18n.t('blocks.examples.img.retail[2]')},
        {url: '/images/qr_exampl/ru13.jpg', name: i18n.t('blocks.examples.img.retail[3]')},
        {url: '/images/qr_exampl/ru7.jpg', name: i18n.t('blocks.examples.img.retail[4]')},
        {url: '/images/qr_exampl/ru4.jpg', name: i18n.t('blocks.examples.img.retail[5]')},        
        {url: '/images/qr_exampl/ru1.jpg', name: i18n.t('blocks.examples.img.retail[6]')},
      ],
      building_control: [
        {url: '/images/qr_exampl/ru9.jpg', name: i18n.t('blocks.examples.img.building_control[0]')},
        {url: '/images/qr_exampl/ru10.jpg', name: i18n.t('blocks.examples.img.building_control[1]')},
        {url: '/images/qr_exampl/ru24.jpg', name: i18n.t('blocks.examples.img.building_control[2]')},
      ],
      other: [
        {url: '/images/qr_exampl/ru17.jpg', name: i18n.t('blocks.examples.img.other[0]')},
        {url: '/images/qr_exampl/ru18.jpg', name: i18n.t('blocks.examples.img.other[1]')},
        {url: '/images/qr_exampl/ru19.jpg', name: i18n.t('blocks.examples.img.other[2]')},
        {url: '/images/qr_exampl/ru21.jpg', name: i18n.t('blocks.examples.img.other[3]')},
        {url: '/images/qr_exampl/ru26.jpg', name: i18n.t('blocks.examples.img.other[4]')},
      ],
    }
    return imgColection
    }
  },
  data() {
    return {            
      activeCategory: {name: i18n.t('blocks.examples.categories[0]'), id: 'service_business'},      
    };
  },
  methods: {
    categorySelect(category){
      this.activeCategory = category
    }
  },
  
};
</script>

<style lang="scss" scoped>


  label{
    font-size: 0.8em;    
  }
  .qr-example{

    margin-top: 8%;
    margin-bottom: 5%;
    
  &__item{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  &__description{
    font-size: 0.8rem;
  }
  
  
}

</style>
