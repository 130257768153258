<template>
      <!-- item string -->
    <div class="faq-item mb-2 p-3 rounded shadow ">

      <!-- title -->
      <div class="flex_start_center">
        <span class="faq-item__title">{{title}}</span>
        <div class="ml-auto" @click="opened=!opened">
          <b-icon-chevron-right v-if="!opened"/>
          <b-icon-chevron-down v-else />
        </div>
      </div>

      <!-- описание -->
      <div class="border-top pt-4 mt-2" v-show="opened">
        <span v-html="description"></span>
      </div>

    </div>    


</template>

<script>


export default {
  name: "faq-item",
  components: { 
      
  },
  props: {    
    title: String,
    description: String,
  },
  computed: {
    
  },
  data() {
    return { 
      opened: false     
    };
  },
  methods: {
    
  },
  
};
</script>

<style lang="scss" scoped>
.faq-item{
  &__title{
    font-size: 1.2em;
  }
}
</style>
