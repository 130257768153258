<template>
  <section id="prices" class="prices bg-light shadow">
    <!-- examples -->
    <div class="container">
      <b-row class="mt-4">
        <b-col></b-col>
        <b-col md="9">
          <h2>{{ $t("blocks.prices.title") }}</h2>

          <!-- header -->
          <b-row>
            <b-col cols="6" md="8" class="border-bottom"> </b-col>
            <b-col
              cols="3"
              md="2"
              class="
                prices__header
                p-2
                mt-4
                text-center
                bg-info
                text-white
                border-bottom
              "
            >
              {{ $t("blocks.prices.tariff_Free") }}
            </b-col>
            <b-col
              cols="3"
              md="2"
              class="
                prices__header
                p-2
                text-center
                bg-primary
                text-white
                border-bottom
              "
            >
              <p class="mb-0">{{ $t("blocks.prices.tariff_Standart") }}</p>
              <strong>{{ $t("blocks.prices.tariff_Standart_price") }}</strong
              ><span class="font-weight-lighter ml-1 prices__header__month"
                >/ {{ $t("blocks.prices.tariff_Standart_month") }}</span
              >
            </b-col>
          </b-row>

          <!-- строки -->
          <FeatureItem
            v-for="(feature, index) in features"
            :key="'feature_id_' + index"
            :name="feature.name"
            :freeTariffIncluded="feature.freeTariffIncluded"
            :mainTariffIncluded="feature.mainTariffIncluded"
          />

          <!-- footer -->
          <b-row>
            <b-col cols="6" md="8" class=""> </b-col>
            <b-col
              cols="3"
              md="2"
              class="prices__footer p-2 mb-4 text-center bg-info text-white"
            >
              {{ $t("blocks.prices.tariff_Free") }}
            </b-col>
            <b-col
              cols="3"
              md="2"
              class="prices__footer p-2 text-center bg-primary text-white"
            >
              <p class="mb-0">{{ $t("blocks.prices.tariff_Standart") }}</p>
              <strong>{{ $t("blocks.prices.tariff_Standart_price") }}</strong
              ><span class="font-weight-lighter ml-1 prices__footer__month"
                >/ {{ $t("blocks.prices.tariff_Standart_month") }}</span
              >
            </b-col>
          </b-row>
        </b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col class="flex_col_center_center">
          <b-button class="prices__join-btn" variant="danger" pill size="lg">{{
            $t("blocks.prices.cta_button")
          }}</b-button>
          <p class="description mt-2">
            {{ $t("blocks.prices.cta_button_description") }}
          </p>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import FeatureItem from "./FeatureItem";
import i18n from "@/i18n";

export default {
  name: "Prices",
  components: {
    FeatureItem,
  },
  computed: {
    features(){
      return [
          {
            freeTariffIncluded: true,
            mainTariffIncluded: true,
            name: i18n.t("blocks.prices.features[0]"),
          },
          {
            freeTariffIncluded: true,
            mainTariffIncluded: true,
            name: i18n.t("blocks.prices.features[1]"),
          },
          {
            freeTariffIncluded: true,
            mainTariffIncluded: true,
            name: i18n.t("blocks.prices.features[2]"),
          },
          {
            freeTariffIncluded: true,
            mainTariffIncluded: true,
            name: i18n.t("blocks.prices.features[3]"),
          },
          {
            freeTariffIncluded: true,
            mainTariffIncluded: true,
            name: i18n.t("blocks.prices.features[4]"),
          },
          {
            freeTariffIncluded: true,
            mainTariffIncluded: true,
            name: i18n.t("blocks.prices.features[5]"),
          },
          {
            freeTariffIncluded: false,
            mainTariffIncluded: true,
            name: i18n.t("blocks.prices.features[6]"),
          },
        ]      
    }
  },
  data() {
    return {
      
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.prices {
  margin-top: 5%;
  margin-bottom: 5%;
  padding-top: 5%;
  padding-bottom: 5%;

  &__header {
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    &__month {
      font-size: 0.7em;
    }
  }
  &__footer {
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    &__month {
      font-size: 0.7em;
    }
  }
  @media (max-width: 420px) {
    padding-bottom: 10%;
    margin-bottom: 10%;

    &__header __footer {
      font-size: small;
    }
  }

  &__join-btn {
    padding-left: 2em;
    padding-right: 2em;
    margin-top: 8%;
  }
}
</style>
