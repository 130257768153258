<template>
  <section id="joincontacts" class="join-contacts container">

    <!-- cards -->
    <b-card-group deck>
      <b-card bg-variant="light" class="shadow"> 

        <p class="join-contacts__title"> {{ $t("blocks.join.contact_title") }}</p>
        <p>{{ $t("blocks.join.contact_text") }}</p>

        <div class="flex_start_center">
          <b-icon-telegram variant="primary"/>
          <b-link :href="$t('contacts.telegram.url')" 
                  class="ml-2 contact-link">
                  
                  {{ $t("contacts.telegram.title") }}</b-link>
        </div>

        <div class="flex_start_center mt-2">
          <b-icon-envelope variant="primary"/>
          <b-link :href="'mailto:'+$t('contacts.mail.address')" 
            class="ml-2 contact-link">
            
            {{ $t("contacts.mail.address") }}</b-link>
        </div>


      </b-card>

      <b-card bg-variant="primary" text-variant="white" class="shadow">
        <b-card-body class="flex_col_start_center">
          <p class="join-contacts__title">{{ $t("blocks.join.self_title")}}</p>
          <b-button pill size="lg" variant="light" class="text-primary"
            :href="$t('contacts.partners.login_url')">
            
            {{ $t("blocks.join.self_btn")}}</b-button>
        </b-card-body>
      </b-card>
    </b-card-group>

    
  </section>
</template>

<script>
export default {
  name: "GetContact",
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.join-contacts {
  padding-bottom: 5rem;
  @media (max-width: 420px) {
    padding: 0.5rem;
  }

  &__title{
    font-size: 1.5em;
    font-weight: 600;
    padding-bottom: 0.8rem;
  }
}

.contact-link {
  color: inherit;
  font-weight: 200;
  font-size: 110%;
}
.contact-link:hover {
  color: blue;
  text-decoration: none;
}
</style>
